<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="localStoredValue"
      :isValid="isValid"
      v-on="$listeners"
    ></fieldsRows>
    <div class="mb-3">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation"> </containerTitle>
        <v-card-text>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="evalArray"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="stepType + '_' + stepID + '_eval'"
            :isValid="isValid"
            v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>

    <explorerOnError
      v-model="onErrorVal"
      :result="{
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.stepType + '_' + this.stepID,
      }"
    ></explorerOnError>
    <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";

import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import lineButtons from "@/cfg/lineButtons.json";
import options from "@/cfg/options.json";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import explorerCollectStatistics from "@/components/legacy/explorerCollectStatistics.vue";

export default {
  components: {
    fieldsRows,
    lineFieldsArray,
    explorerOnError,
    containerTitle,
    explorerCollectStatistics,
  },
  data() {
    return {
      options: options,
    };
  },
  props: ["result", "stepStoredValue", "stepType", "stepID", "isValid"],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        console.log(newVal);
        this.$set(this.stepStoredValue, "statistic", newVal);
        this.$emit("input", this.stepStoredValue);
      },
    },
    fields() {
      let fields = {
        type: {
          type: "combobox",
          associatedOption: [
            ["uplink", "Uplink"],
            ["downlink", "Downlink"],
            ["ping", "Ping"],
          ],
          name: "Type",
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.result.additional.explorerEvalOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "number",

          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },
    evalArray: {
      get() {
        let pairArray = [];

        if (
          typeof this.stepStoredValue["res"] != "undefined" &&
          typeof this.stepStoredValue["res"]["eval"] != "undefined"
        ) {
          pairArray = this.stepStoredValue["res"]["eval"];
        }

        return pairArray;
      },
    },

    localStoredValue: {
      get() {
        // console.log(this.stepStoredValue);
        if (typeof this.stepStoredValue.startWithinMinutes == "undefined") {
          this.$emit("stored-value-changed", {
            ...teststepDefault[this.stepType],

            res: {
              onError: {
                action: "stopTest",
                result: "error",
              },
              eval: [],
            },
            statistic: {
              collectTime: "monitoring_time",
            },
          });
        }
        return this.stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Start Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {
                  style: "max-width: 170px;",
                },
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Finished Within Minutes",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {
                  style: "max-width: 170px;",
                },
              },
              maxDuration: {
                type: "number",
                name: "Maximum Duration (s)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: {
                  style: "max-width: 170px;",
                },
              },
            },
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
    onErrorVal: {
      get() {
        let onErrorVal = {};
        if (typeof this.stepStoredValue.res != "undefined") {
          onErrorVal = this.stepStoredValue.res;
        }
        return onErrorVal;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    checkInput(newValueArray) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", []);
      }
      this.$set(localStoredStep.res, "eval", newValueArray);
      this.$emit("stored-value-changed", localStoredStep);
    },

    updateFieldValue(field, value) {
      let localStoredStep = { ...this.stepStoredValue };
      if (!isNaN(parseInt(value))) {
        value = parseInt(value);
      }
      this.$set(localStoredStep, field, value);

      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>